<template>
  <div style="background-color: white">
    <headertop />
    <div class="title">
      <div class="titletop">
        <div>
          <p id="bread">当前位置 : 网站首页 > 信息公告 > {{ title }}</p>
        </div>
        <b>{{ title }}</b>
        <p>
          日期：{{ date | dateformat("YYYY-MM-DD") }}&nbsp;&nbsp;&nbsp;<span
            >浏览数量：{{ amount }}</span
          >
        </p>
      </div>
      <div v-html="detail" class="detail"></div>
    </div>
    <bottombar />
  </div>
</template>

<script>
import headertop from "@/components/headertop.vue";
import bottombar from "@/components/bottombar.vue";
import { getinfodetail } from "@/api/test";
export default {
  components: {
    headertop,
    bottombar,
  },
  data() {
    return {
      title: "",
      date: "",
      amount: "",
      detail: "",
      newsid: this.$route.query.newsid,
    };
  },
  created() {
    this.initData();
    console.log(this.newsid);
  },
  methods: {
    initData() {
      getinfodetail().then((res) => {
        console.log(res.data);
        res.data.data.map((item) => {
          if (this.newsid == item.newsid) {
            this.title = item.title;
            this.date = item.releasedate;
            this.amount = item.click;
            this.detail = item.content;
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.title {
  /* height: 200px; */
  width: 958px;
  /* background-color: violet; */
  margin: 0 auto;
  text-align: center;
  border-bottom: 1px solid #eeeeee;
}
.titletop {
  padding: 70px;
}
#bread{
  font-size: 16px;
  position: absolute;
  top: 33px;
  left: -185px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
}
.title b {
  font-size: 32px;
  display: block;
  margin-top: 90px;
}
.title p {
  font-size: 17px;
  color: #555555;
  margin-top: 50px;
  margin-left: 540px;
}
.detail {
  margin-bottom: 40px;
  text-align: left;
  line-height: 32px;
  /* background-color: violet; */
}
</style>>
